import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

const IndexPage = () => (
  <Layout>
    <Seo title="Rocket 326" />
    <div style={{ flexGrow: 1 }}>
      <h2>
        Passionate <u>design</u> and <u>development</u>
      </h2>
      <h3>
        We pair thoughtful, researched design with experienced technical
        delivery for high value outcomes.
      </h3>
    </div>
    <p style={{ flexShrink: 0 }}>
      Reach out to discuss your requirements:{" "}
      <a href="mailto: hello@rocket326.com">
        <strong>hello@rocket326.com</strong>
      </a>
    </p>
  </Layout>
)

export default IndexPage
